<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/study/study"
          >学习中心</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">课程列表</a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 分类tabs -->
    <div class="tabs">
      <p
        v-show="tabData.length > 1"
        class="tabItem"
        @click="tabChange(index)"
        :class="{ active: index == tabIndex }"
        v-for="(item, index) in tabData"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>

    <div class="course-list">
      <div v-for="item in courseData" class="course-item" :key="item.courseId">
        <div class="itemleft">
          <div class="lefttop">
            <div class="side-l" @click="goStudy(item)">
              <img
                :src="item.coursePicture"
                @error="$event.target.src = GLOBAL.defaultImg"
                alt=""
              />
            </div>
            <div class="side-r">
              <p class="name" @click="goStudy(item)">
                {{ item.courseName}}
                <i v-show="item.type != 1"  class="tag" :class="{ 'tag-yellow': item.type == 2 }">
                  {{ item.type == 2 ? "免费": item.type == 3 ? "附赠": ""}}
                </i>
              </p>
              <!-- 线下培训地点 -->
              <p
                class="teacherName"
                v-if="item.province && item.city && item.district"
              >
                <img
                  width="11"
                  height="14"
                  src="@/assets/image/index/roup979.png"
                  alt=""
                />
                {{ item.provinceTxt }} {{ item.cityTxt }} |
                {{ item.districtTxt }}
              </p>
              <div class="lable" v-else>
                {{ item.name }}<i>丨</i>视频数量：{{ item.classHour }}个
                <span class="progress">
                  <img src="@/assets/image/personalCenter/2022092301.png" alt="" />
                  {{item.studyProgress? "已学" + (item.studyProgress * 100).toFixed(0) + "%": "不足1%"}}
                </span>
              </div>
              <!-- 线下培训时间 -->
              <p
                class="teacherName"
                v-if="item.openStartTime && item.openEndTime && item.offline== 1"
              >
                <img
                  width="11"
                  height="14"
                  src="@/assets/image/index/roup980.png"
                  alt=""
                />
                {{ item.openStartTime }} - {{ item.openEndTime }}
              </p>
              <div v-else class="time-inner">
                <!-- {{item.validDayType}} -->
                <div class="time-item">
                  <img
                    src="@/assets/image/personalCenter/2022050910.png"
                    alt=""
                  />
                  <span class="timetxt" v-if="item.validDayType == 2"
                    >有效期：长期</span
                  >
                  <span class="timetxt" v-else-if="item.startTime"
                    >有效期：{{ item.startTime }} 至
                    {{ item.endTime }}</span
                  >
                  <span class="timetxt" v-else-if="item.validDayType == 1 && !item.startTime">有效期：{{ item.validDays }}天</span>
                  <span
                    v-if="item.isDelay"
                    @click="toCourseDelay(item)"
                    class="buttxt"
                  >
                  <img src="@/assets/image/study/Group2684.png" alt=""/>
                    课程延期
                  </span>
                </div>
                <div v-if="item.isChangeOpenTime" class="time-item">
                  <img
                    src="@/assets/image/personalCenter/2022050911.png"
                    alt=""
                  />
                  <span class="timetxt">
                    班级期数：{{ item.openStartTime }} 至 {{ item.openEndTime }}
                  </span>
                  <span
                    @click="showModal(item)"
                    v-if="item.isChangeClass"
                    class="buttxt"
                    ><img
                      src="@/assets/image/study/Group1596.png"
                      alt=""
                    />更换班级
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemright">
          <!-- 课程进度和按钮 -->
          <div class="leftbottom">
            <div class="foot">
              <a-button
                class="all-btn-small"
                v-if="item.isOpenClass == 2"
                type="primary"
                disabled
              >
                已过期
              </a-button>
              <p class="all-btn-small" @click="goStudy(item)">
                {{ item.isOpenClass == 1 ? "待开课" : item.status == 1 ? '去学习' : item.status == 2 ? '已过期' : '' }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <a-spin class="load" v-show="loading" size="large" />
      <!-- 暂无课程 -->
      <a-empty
        v-if="courseData.length == 0 && !loading"
        description=""
        style="padding-top: 50px"
        :image="require('@/assets/image/stateHint/icon_nocourse.png')"
        :image-style="{
          height: '180px',
        }"
      >
        <span class="nodata">暂无课程，<a @click="goShop">去逛逛</a></span>
      </a-empty>
      <!-- <div class="page">
        <a-pagination :current="courseObj.pageNum" :defaultPageSize="courseObj.pageSize" :total="courseObj.total" @change="onChange" show-quick-jumper />
      </div> -->
    </div>
    <div></div>
    <!-- 延期弹窗 -->
    <a-modal
      title="Title"
      :visible="visible"
      :closable="false"
      :confirm-loading="confirmLoading"
      :width="602"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <div class="bottombtn">
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" :loading="loading" @click="onDelay()">
            确认
          </a-button>
        </div>
      </template>
      <div class="centers">
        <div class="h4">申请延期须知</div>
        <p v-if="delayCode == 1005">
          1.本次延期为您首次对本课程进行延期，无需缴纳费用；<br /><br />
          2.申请延期后课程有效期顺延{{classdata.days}}天，申请成功后即生效，有效期最后一天23:59:59秒失效；
          <br /><br />
          3.有效期内课程观看次数、章节自测题答题次数均不受限制；
          <br /><br />
          4.超过有效期后如需再次学习，每次延期需缴纳{{classdata.nextPrice}}元延期费，请您合理安排学习时间；
          <br /><br />
          5.支付成功后不支持退款。
        </p>
        <p v-else>
          1.本次延期为您第{{ classdata.delayCount }}次对课程进行延期，延期费为{{
            classdata.price
          }}元，缴纳费用后课程有效期顺延{{ classdata.days }}天: <br /><br />
          2.支付成功后即生效，有效期最后一天23:59:59秒失效。
          <br /><br />
          3.有效期内课程观看次数、章节自测题答题次数均不受限制;
          <br /><br />
          4.超过有效期后如需再次学习，则需以本计费规则再次缴纳延期费用，请您合理安排学习时间;
          <br /><br />
          5.支付成功后不支持退款。
        </p>
      </div>
    </a-modal>
    <!-- 答题记录和更换班级弹窗 -->
    <change
      :isAddInvoiceModal="isAddInvoiceModal"
      :courseId="courseId"
      :openTimeId="openTimeId"
      @onCancelAdd="onCancelAdd"
      @onConfirmAdd="onConfirmAdd"
    ></change>
    <!-- 历史记录 -->
    <history
      :ifHistory="ifHistory"
      :courseId="courseId"
      @onino="onino"
    ></history>
    <!-- 卷子 -->
    <analog
      :ifanalog="ifanalog"
      :configId="configId"
      :opendata="opendata"
      @onana="closeana"
    ></analog>
  </div>
</template>

<script>
import change from "@/components/study/change.vue"; // 添加发票抬头
import history from "../../components/study/history.vue";
// import History from "../../components/study/history.vue"; // 历史记录
import analog from "../../components/study/analog.vue"; // 考试记录

export default {
  // 可用组件的哈希表
  components: { change, history, analog },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      current: 3,
      courseData: [],
      tabData: [],
      tabIndex: 0,
      // 弹窗内容
      visible: false,
      confirmLoading: false,
      courseId: "", //课程id
      openTimeId: "", //班级id
      isAddInvoiceModal: false, //更换班级弹框
      // 历史记录弹窗
      ifHistory: false, //判断
      // 考试记录弹窗
      ifanalog: false, //判断
      configId: null, //模拟考试id
      opendata: {}, //卷子数据
      // 课程延期数据
      delayCode: null,
      classdata: {},
      courseObj: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      studyLoad:false,
      delayLoad:false,
    };
  },
  // 事件处理器
  methods: {
    tabChange(i){
      this.tabIndex = i
      this.courseData = this.tabData[i].courseList;
    },

    onChange(current) {
      this.courseObj.pageNum = current;
      this.getCourseList()
    },
    // 延期查询
    toCourseDelay(e) {
      this.$ajax({
        url: "/hxclass-pc/course/check-delay-course",
        params: {
          courseId: e.courseId,
        },
      }).then((res) => {
        if (res.success) {
          this.classdata = res.data;
          this.delayCode = res.code;
          this.classdata.courseId = e.courseId;
          this.visible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 延期
    onDelay() {
      if (this.delayCode == 1005) {
        if (this.delayLoad) return;
        this.delayLoad = true; // 延期防抖
        // 免费延期直接走接口
        this.$ajax({
          url: "/hxclass-pc/course/delay-course",
          method: "POST",
          params: {
            courseDelayId: this.classdata.courseDelayId,
            courseId: this.classdata.courseId,
            days: this.classdata.days,
            price: this.classdata.price,
          },
        }).then((res) => {
          this.delayLoad = false;
          if (res.code == 200 && res.success) {
            this.visible = false;
            this.$message.success("申请成功");
            this.getCourseList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        // 付费延期走订单
        let list = [];
        list.push({
          productId: this.classdata.courseDelayId, // 商品id
          count: 1, // 数量")
          openTimeId: "", // 班级id
          type: 6, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province: "", // 省
          city: "", // 市
          noGift: 0, // 是否为赠品 0否1是t
          productList: [],
        });
        this.$store.commit("updatelist", list);
        this.$store.commit("updateType", 4);
        this.$store.commit("updateInvoiceId", null);
        this.$store.commit("updateShortInvoiceId", null);
        this.$store.commit("updateAddress", null);
        this.$store.commit("updateCouponList", null);
        this.$router.push("/order/confirmOrder");
      }
    },
    //  取消按钮
    handleCancel(e) {
      this.visible = false;
    },
    goShop() {
      this.$router.push("/knowledgeMall/shopHome");
    },
    // 获取课程列表
    getCourseList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/course/my-classification",
        method: "get",
        params: {
          pageNum: this.courseObj.pageNum,
          pageSize: this.courseObj.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.courseData = res.data.records;
          this.tabData = res.data;
          this.courseData = res.data[this.tabIndex].courseList;
          // this.courseObj.total = res.data[0].courseList.length;
        }
      });
    },
    goStudy(e) {
      if (e.isOpenClass == 2) {
        return;
      }
      // 没有视频的培训
      if(e.hasSectionFlag==0&&!e.hasVideoFlag&&e.courseClass==2){
        this.$message.warning("您已报名该培训，请根据培训时间参加培训。");
        return
      }
      if (e.status == 2) {
        this.$message.warning("请申请延期后学习");
        return;
      }
      if (!e.hasVideoFlag && !e.hasSectionFlag) {// 没有章节、没有视频，提示文字
        // return this.$message.warning("课程暂未更新，请耐心等待开课")
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
        return
      }
      if (e.isOpenClass == 1) {
        let arr = e.startTime.split("-");
        this.$message.warning(
          "课程学习尚未开始，开始时间为" +
            arr[0] +
            "年" +
            arr[1] +
            "月" +
            arr[2] +
            "日"
        );
      } else {
        if(this.studyLoad){
          return
        }
        this.studyLoad = true
        if(e.watchByCatalogueOrder == 1){
          this.$ajax({
          url: "/hxclass-pc/course/go-study",
          params: {
            courseId: e.courseId,
          },
          }).then((res) => {
            this.studyLoad = false
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/course?courseId=" +
                  this.$AES.encode_data(String(e.courseId)) +
                  "&isBuy=" +
                  "&isHave=1"
              );
            } else {
              if (res.code == -2) {
                let str =
                  res.message +
                  "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                res.message = str;
              }
              this.$message.warning(res.message);
            }
          });
        } else {
          this.$ajax({
            url: "/hxclass-pc/course/getUserCurrentSection",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            this.studyLoad = false
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/courseRandom?courseId=" +
                this.$AES.encode_data(String(e.courseId)) +
                "&isHave=1"
              );
            }else{
              this.$message.warning(res.message);
            }
          });
        }
      }
    },
    // 弹窗操作
    showModal(e) {
      // console.log(e);
      this.courseId = e.courseId;
      this.openTimeId = e.openTimeId;
      this.isAddInvoiceModal = true;
    },
    // 更换班级 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 更换班级 - 确定
    onConfirmAdd() {
      this.isAddInvoiceModal = false;
    },
    onselino(e) {
      this.ifHistory = !this.ifHistory;
      this.courseId = e;
    },
    // 历史成绩 - 我知道了
    onino() {
      this.ifHistory = !this.ifHistory;
    },
    // 卷子 - 打开
    onana(e) {
      this.opendata = e;
      this.configId = e.configId;
      this.ifanalog = true;
    },
    // 卷子 - 关闭
    closeana() {
      this.ifanalog = false;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getCourseList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.tabs {
  display: inline-block;
  padding: 0 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: rgba(21, 183, 221, 0.1);
  .tabItem {
    display: inline-block;
    padding: 0 38px;
    line-height: 38px;
    font-size: 18px;
    color: #666666;
    margin-right: 20px;
    cursor: pointer;
  }
  .tabItem:last-child {
    margin: 0;
  }
  .active {
    color: #ffffff;
    background-color: #15b7dd;
  }
}
.course-list {
  position: relative;
  width: 100%;
  min-height: 400px;
  margin-top: 22px;
  padding: 30px;
  background-color: #fff;

  .course-item {
    display: flex;
    padding-bottom: 25px;
    // justify-content: space-between;
    margin-top: 25px;
    border-bottom: 1px solid #f2f2f2;
    .buttxt {
      font-size: 18px;
      font-family: PingFang SC;
      color: #15b7dd;
      text-align: left;
      cursor: pointer;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
      }
    }
    .itemleft {
      width: 1022px;
      .lefttop {
        display: flex;
        .side-l {
          cursor: pointer;
          width: 283px;
          height: 163px;
          border-radius: 5px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            object-fit: cover;
          }
        }
        .side-r {
          flex: 1;
          margin-left: 28px;
          .name {
            cursor: pointer;
            font-size: 22px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 24px;
            color: #333333;
            .tag {
              display: inline-block;
              position: relative;
              top: -4px;
              margin-left: 16px;
              text-align: center;
              line-height: 20px;
              font-style: normal;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              border-radius: 8px 0 8px 0;
              width: 44px;
              height: 20px;
              background: linear-gradient(to right, #3ac5e5, #64e2ff);
            }
            .tag-yellow {
              background: linear-gradient(to right, #ffbd60, #eaa037);
            }
          }
          .lable {
            font-size: 16px;
            display: block;
            font-family: PingFang SC;
            color: #1e97b4;
            margin-top: 18px;
            i {
              margin: 0 5px;
              font-style: normal;
            }
            .progress{
              margin-left: 20px;
              color: #666666;
              img{
                position: relative;
                bottom: 2px;
                width: 16px;
              }
            }
          }

          .time-inner {
            margin-top: 16px;
            // height: 68px;
            .time-item {
              margin-top: 10px;
              img {
                position: relative;
                top: -2px;
                width: 20px;
                margin-right: 6px;
              }
            }
            .timetxt {
              line-height: 21px;
              font-size: 18px;
              font-family: PingFang SC;
              color: #666666;
            }
            .buttxt {
              margin-left: 20px;
            }
          }
        }
      }

      .foot {
        margin-top: 30px;
        display: flex;
        width: 870px;
        justify-content: space-between;
        .speed {
          width: 70%;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          /deep/ .ant-progress-bg {
            height: 16px !important;
          }
          .bar {
            position: relative;
            width: 397px;
            i {
              width: 5px;
              height: 5px;
              background: #1e97b4;
              border-radius: 50%;
              position: absolute;
              right: 10px;
              top: 10px;
            }
            /deep/ .ant-progress-bg {
              border-right: 2px solid #fff;
            }
          }
          .value {
            display: inline-block;
            padding-left: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            min-width: 70px;
          }
        }
      }
    }
    .itemright {
      position: relative;
      .leftbottom {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.nodata {
  font-size: 20px;
  color: #2d3951;
  a {
    color: #15b7dd;
  }
}
.load {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page{
  margin-top: 30px;
  text-align: right;
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
}
.centers {
  width: 100%;
  .h4 {
    font-size: 20px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  p {
    margin-top: 28px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
.bottombtn {
  text-align: center;
  padding-bottom: 30px;
  .ant-btn {
    width: 113px;
    height: 36px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 18px;
  }
}
/deep/.ant-modal-footer {
  border-top: #15b7dd 0px solid;
}
/deep/.ant-modal-header {
  display: none;
}
.course-item:first-child{
  margin-top: 0;
}
@media screen and (max-width: 16000px) {
  .course-list .course-item {
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .course-list .course-item:first-child {
    margin-top: 0;
  }
    
  .course-list .course-item .itemleft {
    width: 920px;
  }

  .course-list .course-item .side-l {
    width: 180px;
    height: 180px;
  }
  .course-list .course-item .side-r .name {
    font-size: 22px;
  }
  .course-list .course-item .side-r .lable {
    font-size: 16px;
  }
  .course-list .course-item .side-r .time-inner {
    margin-top: 16px;
    // height: 24px;
  }
  .all-btn-small {
    width: 110px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
  }
  .course-list .course-item .side-r .foot {
    margin-top: 1px;
  }

  .course-list .course-item {
    .itemleft {
      .lefttop {
        .side-l {
          width: 217px;
          height: 125px;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
        }
        .side-r {
          .name {
            font-size: 16px;
          }
          .lable {
            font-size: 14px;
          }
          .time-inner {
            .time-item {
              .timetxt {
                font-size: 14px;
              }
              img {
                width: 16px;
              }
              .buttxt {
                font-size: 14px;
              }
            }
          }
        }
      }
      .foot {
        width: 680px;
        .speed .bar {
          width: 349px;
        }
      }
    }
  }
}
.teacherName {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFangMedium;
  color: #666666;
  line-height: 21px;
}
</style>
